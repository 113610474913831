'use client';

import Image from 'next/legacy/image';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import images from '~build-config/images.json';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/etc';
import PageUrls from '~constants/page-urls';

const NotFoundPage: FC = () => {
  const { t } = useTranslation(['buttons', 'etc']);
  return (
    <div className="bg-layout relative flex min-h-screen flex-col items-center justify-center overflow-hidden text-white">
      <div className="flex h-[128px] w-[384px] justify-center">
        <Image
          className="object-contain"
          height={128}
          src={images.horizontalLogo}
          unoptimized
          width={210}
        />
      </div>
      <div className="my-10 flex flex-col items-center gap-4 text-center">
        <p className="text-3xl font-bold">Oops!!!</p>
        <p className="text-xl font-bold">404 - PAGE NOT FOUND</p>
        <div>
          <p>{t('etc:not-found-page')}</p>
          <p>{t('etc:not-found-page-description')}</p>
        </div>
        <div className="mt-4 w-4/5 space-y-2">
          <Button className="w-full" href={PageUrls.HOME}>
            {t('buttons:btn-back-to-home')}
          </Button>
          <Button
            className="w-full"
            color={ButtonColors.DEFAULT}
            href={PageUrls.CONTACT}
          >
            {t('buttons:btn-contact-call-center')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
